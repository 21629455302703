import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import { addBusinessDays, format, eachDayOfInterval } from "date-fns";
import { OptionTypeBase } from "react-select";
import { useValidation } from "hooks";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { validaCPF } from "utils";

import { IUnidade } from "interfaces/unidade";
import { Scaffold } from "components/Shared";
import {
  CreateProspectActions,
  CreateProspectState,
} from "store/ducks/prospect";
import {
  ListBookingActions,
  ListBookingState,
  CreateBookingActions,
  CreateBookingState,
} from "store/ducks/booking";
import { CampaignState } from "store/ducks/campaign";
// import { ChkCpfActions, iChkCpf } from "store/ducks/booking";
import { fetchTagIndex, FetchTagIndexActions } from "store/ducks/unidades";

import { Select, Input, InputMask } from "components/Shared/Form";
import GTMDataLayer from "utils/GTM-dataLayer";
import * as S from "./styles";
import { Mobile } from "styled-icons/entypo";
interface IParams {
  idUnidade: string;
}

interface ILocationState {
  state: IUnidade;
}

const dateRange = eachDayOfInterval({
  start: addBusinessDays(new Date(), 1),
  end: addBusinessDays(new Date(), 5),
});

const dateRangeOptions = dateRange.map((date) => ({
  value: format(date, "yyyy-MM-dd"),
  label: format(date, "dd/MM/yyyy"),
}));

const sexoOptions = [
  {
    value: 1,
    label: "Feminino",
  },
  {
    value: 2,
    label: "Masculino",
  },
];

interface iSelectedService {
  idProduto: number;
}

export const NewBooking: React.FC = () => {
  const [dateSelected, setDateSelected] = useState<OptionTypeBase | null>(null);
  const [sexoSelect, setSexoSelect] = useState<OptionTypeBase | null>(null);
  //estado que armazena as opçao de tratamento provenientes da api
  const [optionsTreatments, setOptionsTreatments] = useState<OptionTypeBase>(
    []
  );
  const celularFieldRef = useRef<HTMLInputElement | null>(null);
  //estado que armazena as opão de servico já formatadas para o envio
  const [selectedServices, setSelectedServices] = useState<iSelectedService[]>(
    []
  );

  //estados para o formulario
  const [formNome, setFormNome] = useState<string>("");
  const [formCpf, setFormCpf] = useState<string>("");
  const [formCelular, setFormCelular] = useState<string>("");
  const [formEmail, setFormEmail] = useState<string>("");
  const [clickedLinks, setClickedLinks] = useState<{ [key: number]: boolean }>(
    {}
  );

  const { idUnidade } = useParams<IParams>();
  const location: ILocationState = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const formBookingRef = useRef<FormHandles>(null);

  const { handleFormErrors } = useValidation();

  const { data: prospectData, loading: prospectLoading } =
    useSelector<RootStateOrAny>(
      (state) => state.createProspect
    ) as CreateProspectState;

  const { data: bookingData, loading: bookingLoading } =
    useSelector<RootStateOrAny>(
      (state) => state.listBooking
    ) as ListBookingState;

  const { loading: createBookingLoading } = useSelector<RootStateOrAny>(
    (state) => state.createBooking
  ) as CreateBookingState;

  const { data: campaignData } = useSelector<RootStateOrAny, CampaignState>(
    (state) => state.campaign
  );

  const { data: tagIndex } = useSelector<RootStateOrAny, CampaignState>(
    (state) => state.fetchTagIndex
  );

  const onCreateBooking = useCallback(
    (data) => {
      setDateSelected(null);
      history.push({
        pathname: "/avaliacao",
        state: {
          unidade: location.state,
          avaliacao: data,
          prospect: prospectData,
        },
      });
    },
    [history, location.state, prospectData]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          Nome: Yup.string().required("Obrigatório"),
          Cpf: Yup.string()
            .test("validaCPF", "CPF inválido", (value: any) => {
              return validaCPF(value);
            })
            .required("Obrigatório"),
          Celular: Yup.string().required("Obrigatório"),
          Email: Yup.string().required("Obrigatório"),

          // Sexo: Yup.string().required("Obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const selecionados = data.ProdutosInteresse
          ? JSON.parse(data.ProdutosInteresse)
          : null;

        //format cpf removing dots and hifens
        data.Cpf = data.Cpf.replace(/\./g, "");
        data.tagIndex = tagIndex;
        data.IdUnidade = idUnidade;
        data.ProdutosInteresse = selecionados;
        ReactPixel.fbq("track", "Schedule");
        ReactGA.ga("event", "conversion", {
          send_to: "AW-640319643/8g13CKKN5_ABEJuBqrEC",
        });

        if (location.state) {
          GTMDataLayer.B2CFormDataUser({
            estado: location.state.UF,
            cidade: location.state.Cidade,
            unidade: location.state.Nome,
            nome: data.Nome,
            cpf: data.Cpf,
            email: data.Email,
            telefone: data.Celular,
            sexo: 0,
            ProdutosInteresse: data.ProdutosInteresse,
          });
        }
        if (campaignData) {
          data.Source = campaignData.utm_source;
          data.Medium = campaignData.utm_medium;
          data.Campaign = campaignData.utm_campaign;
          data.Content = campaignData.utm_content;
          data.Term = campaignData.utm_term;
        }

        dispatch(CreateProspectActions.request(data));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, idUnidade, location]
  );

  const handleSubmitBooking = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formBookingRef.current?.setErrors({});
        const schema = Yup.object().shape({
          DataHoraAvaliacao: Yup.string().required("Obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (location.state) {
          GTMDataLayer.B2CFormData({
            estado: location.state.UF,
            cidade: location.state.Cidade,
            unidade: location.state.Nome,
            nome: data.Nome,
            cpf: data.Cpf,
            email: data.Email,
            telefone: data.Celular,
            sexo: data.Sexo,
            ProdutosInteresse: data.ProdutosInteresse,
            dataHoraAvaliacao: data.DataHoraAvaliacao,
            idProspect: prospectData.IdProspect,
          });
        }

        data.IdProspect = prospectData.IdProspect;
        data.IdUnidade = prospectData.IdUnidade;
        delete data.Nome;
        delete data.Celular;
        delete data.Email;

        if (campaignData) {
          data.source = campaignData?.utm_source || null;
          data.medium = campaignData?.utm_medium || null;
          data.campaign = campaignData?.utm_campaign || null;
          data.content = campaignData?.utm_content || null;
          data.term = campaignData?.utm_term || null;
        }

        dispatch(CreateBookingActions.request(data, onCreateBooking));
      } catch (error) {
        handleFormErrors(error, formBookingRef);
      }
    },
    [campaignData, dispatch, handleFormErrors, onCreateBooking, prospectData]
  );

  const [nomesProdutos, setNomesProdutos] = useState<string[]>([]);

  const mapProdutoNomes = (produtos: any, options: any): string[] => {
    return produtos.map((produto: any) => {
      const foundOption = options.find(
        (option: any) => option.IdProduto === produto.IdProduto
      );
      return foundOption ? foundOption.Nome : null;
    });
  };

  useEffect(() => {
    if (prospectData.Cpf) {
      const mappedNomes = mapProdutoNomes(
        prospectData.ProdutosInteresse,
        optionsTreatments
      );
      setNomesProdutos(mappedNomes);
    }
  }, [prospectData, optionsTreatments]);

  const getDates = useCallback(
    (date) => {
      dispatch(
        ListBookingActions.request({
          idUnidade: idUnidade,
          tagIndex: tagIndex,
          data: date,
        })
      );
    },
    [dispatch, idUnidade, tagIndex]
  );

  const onDateChange = useCallback(
    (option) => {
      setDateSelected(option);
      getDates(option.value);
    },
    [getDates]
  );

  useEffect(() => {
    console.log("atualizado");
  }, [selectedServices]);

  useEffect(() => {
    return () => {
      dispatch(CreateProspectActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    const GetTreatmentsOption = async () => {
      const url = `https://smartinstitucional.orthopride.com.br/api/produtos`;
      const apiKey = "873dad1b-fd94-44de-9c5d-6d2f99676f16";
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            ApiKey: apiKey,
          },
        });

        if (!response.ok) {
          if (response.status === 400) {
            console.error("Erro 400: Erro ao obter servicos.");
            return;
          } else {
            throw new Error(`Erro na consulta: ${response.status}`);
          }
        }
        const data = await response.json();
        if (data) {
          setOptionsTreatments(data);
        }
      } catch (error) {
        console.error("Erro Receber lista de servicos:", error);
        throw error;
      }
    };
    GetTreatmentsOption();
  }, []);

  async function CheckCpf(iCpf: string) {
    const cpfWithDots = iCpf;
    const cpf = cpfWithDots.replace(/\D/g, "");
    const url = `https://smartinstitucional.orthopride.com.br/api/consulta?cpf=${cpf}`;
    const apiKey = "873dad1b-fd94-44de-9c5d-6d2f99676f16";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          ApiKey: apiKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setFormNome(data);
        setFormCpf(cpfWithDots);
        return data;
      } else if (response.status === 400) {
        const errorData = await response.json();
        // const Emessage = errorData.ErrorMessage;
        //associando ao elemente com mensagem de erro
        const ErrMessage = document.getElementById("erroMessageApi");
        //associando ao campo cpf
        const cpfField = document.getElementById("Cpf") as HTMLInputElement;
        if (ErrMessage && cpfField) {
          ErrMessage.style.display = "block";
          setTimeout(() => {
            ErrMessage.style.display = "none";
            setFormCpf("");
            setFormNome("");
            cpfField.value = "";
            cpfField.focus();
          }, 4000);
        }
      }
    } catch (error) {
      console.error("Erro ao consultar o CPF:", error);
      throw error;
    }
  }

  const handleCelular = (MobileValue: string) => {
    setFormCelular(MobileValue);
  };

  const handleBlurCel = (e: any) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    if (numericValue.length === 11) {
      handleCelular(e.target.value);
    }
  };

  const handleBlurEmail = useCallback((e) => {
    setTimeout(() => {
      setFormEmail(e.target.value);
    }, 500);
  }, []);

  async function handleCpfChange(event: any) {
    const cpfWithDots = event.target.value;
    const cpf = cpfWithDots.replace(/\D/g, "");

    if (cpf.length !== 11) {
      // console.warn("CPF deve ter 11 dígitos.");
      return;
    } else {
      try {
        const dataResp = await CheckCpf(cpfWithDots);
        if (dataResp) {
          const celularField = document.getElementById(
            "Celular"
          ) as HTMLInputElement;
          celularField.focus();
        }
      } catch (error) {
        console.error("Erro ao consultar o CPF:", error);
      }
    }
  }

  // Função para lidar com a mudança de cor e estado dos serviços selecionados
  const handleCheckboxChange = (
    id: number,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setClickedLinks((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    setSelectedServices((prevSelectedServices) => {
      const isSelected = prevSelectedServices.some(
        (service) => service.idProduto === id
      );
      if (isSelected) {
        return prevSelectedServices.filter(
          (service) => service.idProduto !== id
        );
      } else {
        return [...prevSelectedServices, { idProduto: id }];
      }
    });
  };

  const ProspectForm: React.FC = () => {
    return (
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="Sexo" type="hidden" value="0" />
        <Input
          name="ProdutosInteresse"
          type="hidden"
          value={JSON.stringify(selectedServices)}
        />
        <InputMask
          name="Cpf"
          onInput={handleCpfChange}
          label="CPF:"
          mask={"999.999.999-99"}
          id="Cpf"
          defaultValue={formCpf}
        />
        <S.ErroMessage id="erroMessageApi">
          O cpf digitado é inválido
        </S.ErroMessage>
        <Input
          name="Nome"
          readOnly={true}
          label="Digite seu nome:"
          defaultValue={formNome}
        />
        <InputMask
          id="Celular"
          name="Celular"
          label="Celular: "
          mask={"(99)99999-9999"}
          defaultValue={formCelular}
          onInput={handleBlurCel}
        />
        <Input
          name="Email"
          label="Email:"
          defaultValue={formEmail}
          onBlur={handleBlurEmail}
        />

        {/* remoção de seleção de serviços a pedido da gerencia */}
        {/* <S.GroupTreatments>
          <h3>Selecione um ou mais serviços:</h3>
          <p id="warningMsg" className="warning">
            Selecione ao menos um serviço abaixo
          </p>
          {optionsTreatments.map((item: any, index: number) => (
            <S.ButtonOpt
              key={index}
              onClick={(e: any) => handleCheckboxChange(item.IdProduto, e)}
              isClicked={!!clickedLinks[item.IdProduto]}
              href="javascript:void(0)"
              rel={item.IdProduto}
            >
              {item.Nome}
            </S.ButtonOpt>
          ))}
        </S.GroupTreatments> */}

        <S.SubmitButton>
          Continuar {prospectLoading ? <S.Loading /> : <S.ArrowRightIcon />}
        </S.SubmitButton>
      </Form>
    );
  };

  const BookingForm: React.FC = () => {
    return (
      <Form ref={formBookingRef} onSubmit={handleSubmitBooking}>
        <Input
          name="Cpf"
          label="Digite seu cpf: "
          defaultValue={prospectData?.Cpf}
          disabled
        />
        <Input
          name="Celular"
          label="Celular: "
          defaultValue={prospectData?.Celular}
          disabled
        />
        <Input
          name="Nome"
          label="Digite seu nome: "
          defaultValue={prospectData?.Nome}
          disabled
        />
        <Input
          name="Email"
          label="Email: "
          defaultValue={prospectData?.Email}
          disabled
        />
        {/* <Select
          name="Sexo"
          label="Sexo: "
          options={sexoOptions}
          onChange={(e) => setSexoSelect(e)}
          defaultValue={sexoSelect}
          isDisabled={true}
        /> */}

        {/* Seleção de tratamentos removida a pedido do cliente */}
        {/* <S.GroupTreatments>
          <p className="legend">Serviços Selecionados</p>
          {nomesProdutos.length > 0 ? (
            <ul>
              {nomesProdutos.map((nome, index) => (
                <li key={index}>{nome}</li>
              ))}
            </ul>
          ) : (
            <p className="noItem">Nenhum produto selecionado.</p>
          )}
        </S.GroupTreatments> */}

        <Select
          name="data"
          label="Selecione uma data:"
          options={dateRangeOptions}
          onChange={(e) => onDateChange(e)}
          defaultValue={dateSelected}
        />
        <Select
          name="DataHoraAvaliacao"
          label="Selecione um horário:"
          isDisabled={bookingLoading}
          isLoading={bookingLoading}
          options={bookingData}
        />
        <S.SubmitButton className="GTM-Avaliacao">
          Enviar {createBookingLoading ? <S.Loading /> : <S.ArrowRightIcon />}
        </S.SubmitButton>
      </Form>
    );
  };

  return (
    <Scaffold>
      <S.Container>
        <S.Content>
          <S.Wrapper>
            {/* <S.BackButton onClick={() => history.push("/agendar-avaliacao")}> */}
            <S.BackButton onClick={() => history.goBack()}>
              <S.BackgroundIcon>
                <S.BackIcon />
              </S.BackgroundIcon>
              Voltar
            </S.BackButton>
            <S.Title>
              Marque sua
              <span> avaliação</span>
            </S.Title>
            <S.Text>
              Preencha seus dados e agende seu horário na unidade:
              <br />
              <span>{location.state.Nome}</span>
            </S.Text>
            <S.Aviso>
              Atenção: Não temos convênio ou parceria com o SUS.
            </S.Aviso>

            {!prospectData.Cpf ? <ProspectForm /> : <BookingForm />}
          </S.Wrapper>
        </S.Content>
      </S.Container>
    </Scaffold>
  );
};
