import TagManager from "react-gtm-module";

type Produto = {
  idProduto: number;
};

interface B2BdataLayerArgs {
  modeloDeInteresse: string;
  estado: string;
  cidade: string;
  nome: string;
  telefone: string;
  produtosInteresse: Produto[] | null;
  email: string;
  idProspect: number;
}

interface B2ClocationDataLayerArgs {
  estado: string;
  cidade: string;
}

interface B2CchosenUnitDataLayerArgs {
  estado: string;
  cidade: string;
  unidade: string;
}

interface B2CuserDataDataLayerArgs {
  estado: string;
  cidade: string;
  unidade: string;
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
  sexo: number;
  ProdutosInteresse: Produto[] | null;
}

interface B2CdataLayerArgs {
  estado: string;
  cidade: string;
  unidade: string;
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
  sexo: number;
  ProdutosInteresse: Produto[] | null;
  dataHoraAvaliacao: string;
  idProspect: string;
}

export default class GTMDataLayer {
  public static B2BForm(dataLayerArg: B2BdataLayerArgs): void {
    TagManager.dataLayer({
      dataLayer: { ...dataLayerArg, event: "B2B-formSent" },
    });
  }

  public static B2CFormLocation(dataLayerArg: B2ClocationDataLayerArgs): void {
    TagManager.dataLayer({
      dataLayer: { ...dataLayerArg, event: "B2C-escolheuLocalizacao" },
    });
  }

  public static B2CFormChosenUnit(
    dataLayerArg: B2CchosenUnitDataLayerArgs
  ): void {
    TagManager.dataLayer({
      dataLayer: { ...dataLayerArg, event: "B2C-escolheuUnidade" },
    });
  }

  public static B2CFormDataUser(dataLayerArg: B2CuserDataDataLayerArgs): void {
    TagManager.dataLayer({
      dataLayer: { ...dataLayerArg, event: "B2C-dadosDoUsuario" },
    });
  }

  public static B2CFormData(dataLayerArg: B2CdataLayerArgs): void {
    TagManager.dataLayer({
      dataLayer: { ...dataLayerArg, event: "B2C-formSent" },
    });
  }
}
