import { v4 as uuidV4 } from "uuid";

export type TCategory =
  | "Tratamento"
  | "Pagamento"
  | "Unidades"
  | "Agendamento"
  | "Aplicativo"
  | "Cancelamento"
  | "Contrato"
  | "Todos"
  | null;
export interface IAsk {
  id: string;
  category: TCategory;
  question: string;
  response: string;
}

export const askAndResponse: Array<IAsk> = [
  {
    id: uuidV4(),
    category: "Agendamento",
    question: "Como faço para alterar o meu agendamento ?",
    response:
      "A melhor forma de realizar o seu reagendamento é através do nosso aplicativo (se ainda não tem o nosso app clique aqui). Você também pode se dirigir à recepção de sua unidade para fazer esta solicitação.",
  },
  {
    id: uuidV4(),
    category: "Aplicativo",
    question: "O que fazer se não consigo acessar o aplicativo do paciente?",
    response:
      "Para que seu aplicativo funcione, além do seu código de paciente, é necessário que seu CPF, e-mail e telefone estejam atualizados na ficha de paciente. Basta entrar em contato com a unidade ou com algum dos nossos canais de atendimento pedindo que os dados sejam atualizados.",
  },
  {
    id: uuidV4(),
    category: "Aplicativo",
    question: "O que é app do paciente?",
    response:
      "O nosso aplicativo é a melhor forma de acompanhar todo o seu trtatamento. Nele você pode agendar ou reagendar suas consultas, ver seus pagamentos e fazer várias outras solicitações sem precisar se dirigir à unidade.",
  },
  {
    id: uuidV4(),
    category: "Aplicativo",
    question: "Que tipos de informações posso ver no app?",
    response:
      "No nosso app do paciente você pode consultar seu agendamento, realizer reagndamento, pagar e consultsar seus pagamentos, acompanhar seu tratamento e fazer outras solicitações. Baixe agora mesmo.",
  },
  {
    id: uuidV4(),
    category: "Aplicativo",
    question: "Que informações eu preciso para baixar o app?",
    response:
      "Você vai precisar do código do paciente, e-mail, CPF e do seu telefone. Éimportante lembrar que seus dados precisam estar atualizados em sua unidade.",
  },
  {
    id: uuidV4(),
    category: "Cancelamento",
    question: "Como faço para cancelar meu contrato?",
    response:
      "Você deve solicitar o distrato na gerência da unidade, lá você poderá optar por duas formas de cancelamento: o aviso prévio ou a multa rescisória.",
  },
  {
    id: uuidV4(),
    category: "Cancelamento",
    question: "O que é o aviso prévio?",
    response:
      "O aviso prévio é uma das opções para cancelamento de contrato e é muito importante para que o dentista programe a retirada do seu aparelho com os devidos cuidados. Você informa à unidade que gostaria de entrar em aviso prévio, paga os 3 meses seguintes e continua se consultando normalmente. O aviso prévio não pode ser cobrado de uma vez, os pagamentos devem ser realizados mensalmente.",
  },
  {
    id: uuidV4(),
    category: "Cancelamento",
    question: "O que é a multa rescisória?",
    response:
      "É o valor cobrado quando o paciente, unilateralmente, solicita o encerramento do tratamento. Caso deseje cancelar o contrato, você poderá optar pela multa rescisória que consiste em 15% do valor total das parcelas do tratamento que ainda vão vencer. Lembrando que uma outra opção ao cancelamento é optar pelo Aviso Prévio.",
  },
  {
    id: uuidV4(),
    category: "Cancelamento",
    question:
      "Tive um problema médico e preciso tirar o aparelho, o que fazer?",
    response:
      "Basta entregar à gerência da unidade um atestado médico justificando a necessidade da remoção do aparelho para que seu cancelamento seja solicitado.",
  },
  {
    id: uuidV4(),
    category: "Cancelamento",
    question: "Como funciona o distrato?",
    response:
      "O distrato indicará que o paciente está interrompendo o tratamento por conta própria antes da liberação do dentista. Seu contrato só será cancelado após assinatura desse documento.",
  },
  {
    id: uuidV4(),
    category: "Contrato",
    question: "Qual a validade do meu contrato?",
    response:
      "Por padrão, os contratos possuem duração de 36 meses, que é o tempo médio estimado para a conclusão do tratamento ortodôntico. Contudo, esse tempo de contrato pode ser alterado para mais ou para menos caso seu dentista julgar necessário. Cada organismo reage de forma diferente ao tratamento. O tratamento só termina oficialmente quando você receber alta de seu dentista.",
  },
  {
    id: uuidV4(),
    category: "Contrato",
    question: "O que é o checklist de contrato?",
    response:
      "São os pontos importantes do contrato resumidos para o paciente. O contrato só poderá ser assinado após o paciente conferir todo o checklist e também assiná-lo.",
  },
  {
    id: uuidV4(),
    category: "Pagamento",
    question: "O pagamento só pode ser feito na unidade?",
    response:
      "Não. Você pode solicitar a emissão de um boleto com no mínimo 3 dias de antecedência ao vencimento à unidade e também pode realizar o pagamento através do nosso aplicativo de paciente. Através do nosso aplicativo há outras formas de pagamento como PIX ou débito recorrente no cartão. Procure saber se sua unidade já habilitou estes recursos.",
  },
  {
    id: uuidV4(),
    category: "Pagamento",
    question: "O que acontece se eu atrasar minha parcela?",
    response:
      "Você tem um desconto se pagar sua parcela até a data do vencimento. Caso não pague até esta data será cobrado o valor integral de sua parcela sem o desconto.",
  },
  // {
  //   id: uuidV4(),
  //   category: "Pagamento",
  //   question: "A Orthopride cobra juros após o vencimento?",
  //   response:
  //     "Não. Oferecemos um desconto por antecipação até a data de vencimento. Após esta data é cobrado o valor integral.",
  // },
  {
    id: uuidV4(),
    category: "Pagamento",
    question: "Minha parcela está paga, por que estão me cobrando?",
    response:
      "O sistema automatizado de cobrança às vezes demora a reconhecer os pagamentos por conta do volume de recebimentos, por isso, às vezes isso pode acontecer. Mas se sua parcela estiver paga, não se preocupe! Você pode desconsiderar a mensagem.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "A Orthopride aceita plano de saúde?",
    response: "Não possuímos convênio com nenhuma operadora de plano de saúde.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "A Orthopride faz tratamento de canal?",
    response:
      "Não. A Orthopride apenas realiza tratamento de ordotontia e estética, ou seja, colocação e manutenção de aparelhos ortodônticos.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Como funciona o atendimento de emergência?",
    response:
      "Geralmente o paciente solicita o atendimento de emergência quando está com algum incômodo, dor ou percebe que alguma peça do seu aparelho soltou. Neste caso, estando com a parcela em dia, basta dirigir-se a qualquer unidade Orthopride em caso de emergência para ser atendido.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "O que é raspagem?",
    response:
      "É o tratamento que remove o tártaro, deixando eles lisos e polidos , reduzindo as chances de sangramento gengival e mantendo a saúde bucal. Antes da colocação do aparelho normalmente indica-se uma raspagem.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "O que fazer se meu aparelho quebrar?",
    response:
      "Procure o quanto antes uma unidade Orthopride para um atendimneto de emergência para que o aparelho seja corrigido e o seu tratamento não se atrase.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Se eu faltar um mês de manutenção, pago mesmo assim?",
    response:
      "Sim. Seu tratamento total é dividido em várias parcelas sendo que 75% do valor devido você recebe logo no início do seu tratamento e se refere à documentação, laudo e instalação do aparelho, por esta razão, você segue pagando as parcelas durante a vigência do contrato.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "O que acontece se eu abandonar o tratamento?",
    response:
      "Nunca é recomendado abandonar o tratamento. Em caso de qualquer tipo de dificuldade procure por sua unidade. Caso o paciente abandone o tratamento a unidade deixa de ser responsável em poucos meses e o contrato é automaticamente encerrado por abandono, mas os valores que estão pendentes ou que serão gerados no período do abandono é devido à unidade.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Como funciona a limpeza incluída no contrato?",
    response:
      "De 4  em 4 meses o dentista realizará a limpeza com jato de bicarbonato para manter a sua higiene e saúde bucal.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "O que é a manutenção?",
    response:
      "A manutenção  tem a principal função de ativar o aparelho de acordo com os movimentos que foram planejados, que pode ser feito pela troca das borrachas, fios e/ou outra aparatologia.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "O que é ASB/TSB?",
    response:
      " O Auxiliar em Saúde Bucal (ASB) e Técnico em Saúde Bucal ( TSB) são profissionais que trabalham sempre sob a supervisão dos dentistas , são fundamentais pois os auxiliam durante todo o atendimento dentro do consultório.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Sou atendido por estagiários?",
    response:
      "Não. Os ASBs e TSBs são profissionais qualificados para auxuliarem os dentistas no dia a dia da clínica. São profissionais que recebem à devida autorização do órgão competente para atuar junto aos dentistas.",
  },
  // {
  //   id: uuidV4(),
  //   category: "Tratamento",
  //   question: "A contenção é paga?",
  //   response:
  //     "Não, ela faz parte do final do tratamento ortodontico. A contenção é um um dos beneficios que a Orthopride oferece.",
  // },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "A partir de quantos anos o tratamento pode ser realizado?",
    response:
      "Em média a partir dos 7 anos de idade, porém cada caso deve ser avaliado individualmente por um profissional.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "A Orthopride faz extração de siso?",
    response:
      "Não. A Orthopride apenas realiza tratamento de ordotontia e estética, ou seja, colocação e manutenção de aparelhos ortodônticos.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "A Orthopride faz restauração?",
    response:
      "Não. A Orthopride apenas realiza tratamento de ordotontia e estética, ou seja, colocação e manutenção de aparelhos ortodônticos.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Meus exames antigos servem para iniciar o tratamento?",
    response:
      "Não. É preciso que seus exames sejam os mais recentes possíveis, por isso a necessidade de realizar novos exames antes de instalar o aparelho.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Como funciona o clareamento?",
    response:
      "Não. A Orthopride apenas realiza tratamento de ordotontia e estética, ou seja, colocação e manutenção de aparelhos ortodônticos.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Como faço para ter acesso aos meus exames?",
    response:
      "A melhor forma de ter acesso aos seus exames é através do aplicativo do paciente.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Posso trocar de dentista?",
    response:
      "Você pode solicitar na gerência de sua unidade a troca do profissional.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Como funciona a manutenção do aparelho?",
    response:
      "O profissional responsável realiza a limpeza, troca de borrachinhas e o fios do seu aparelho. E qualquer outro procedimento necessário para o andamento do seu tratamento.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "O que acontece se eu faltar minha consulta?",
    response:
      "Você precisa reagendar sua consulta o quanto antes para não prejudicar seu tratamento. O reagendamento pode ser feito pelo aplicativo do paciente. A falta na consulta não exclui o pagamento da parcela do mês vigente.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Posso pausar meu tratamento?",
    response:
      "É preciso analisar o caso junto à gerência da unidade e o dentista responsável para saber se é possível que seu tratamento seja pausado.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Quais tipos de aparelho as unidades oferecem?",
    response:
      "Aparelhos ortopédicos faciais, Aparelhos fixos (metálicos, estéticos em cerâmica e safira), Aparelho autoligado ( metálico e estético) e alinhadores transparentes.",
  },
  {
    id: uuidV4(),
    category: "Tratamento",
    question: "Quanto tempo dura o clareamento?",
    response:
      "O clareamento dentário dura em média um ano, porém a durabilidade do resultado vai depender dos cuidados e habitos do paciente, quanto mais alimentos com corantes o cliente ingerir, mais rapidamente os dentes irão escurecer.",
  },
  {
    id: uuidV4(),
    category: "Unidades",
    question: "Como faço para me transferir de unidade?",
    response:
      "Basta estar com a sua parcela em dia e solicitar o termo de transferência ou na sua própria unidade, ou na unidade para onde gostaria de se transferir.",
  },
  {
    id: uuidV4(),
    category: "Unidades",
    question:
      "Vou me mudar para uma cidade que não tem Orthopride. O que fazer?",
    response:
      "Certifique-se se não há unidades em regiões vizinhas onde ao menos, uma vezao mês, você possa ser atendido. Se realmente não houver é preciso procurar sua unidade para comunicar o fato antes de se mudar.",
  },
];
