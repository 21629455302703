import React, { useRef, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  ListUnidadesTags,
  ListUnidadesTagsActions,
} from "store/ducks/unidades";
import { CampaignActions } from "store/ducks/campaign";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { OptionTypeBase } from "react-select";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import { v4 as uuidV4 } from "uuid";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Select } from "components/Shared/Form";
import GTMDataLayer from "utils/GTM-dataLayer";
import { UnitCard } from "components/Shared";
import querystring from "querystring";
import { useValidation } from "hooks";
import * as S from "./styles";

interface IQuery {
  tag: string;
}

interface IParams {
  lineo: string;
  googleCampaign: string;
}

export const UnitsStudio: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);
  const [selectedCity, setSelectedCity] = useState<OptionTypeBase[]>([]);
  const [saveCityes, setSaveCities] = useState<OptionTypeBase[]>([]);
  const [selectedState, setSelectedState] = useState<OptionTypeBase | null>(
    null
  );
  const { lineo, googleCampaign } = useParams<IParams>();

  function ConvertToName(str: string) {
    const BaseState = [
      { nome: "Acre", sigla: "AC" },
      { nome: "Alagoas", sigla: "AL" },
      { nome: "Amapá", sigla: "AP" },
      { nome: "Amazonas", sigla: "AM" },
      { nome: "Bahia", sigla: "BA" },
      { nome: "Ceará", sigla: "CE" },
      { nome: "Distrito Federal", sigla: "DF" },
      { nome: "Espírito Santo", sigla: "ES" },
      { nome: "Goiás", sigla: "GO" },
      { nome: "Maranhão", sigla: "MA" },
      { nome: "Mato Grosso", sigla: "MT" },
      { nome: "Mato Grosso do Sul", sigla: "MS" },
      { nome: "Minas Gerais", sigla: "MG" },
      { nome: "Pará", sigla: "PA" },
      { nome: "Paraíba", sigla: "PB" },
      { nome: "Paraná", sigla: "PR" },
      { nome: "Pernambuco", sigla: "PE" },
      { nome: "Piauí", sigla: "PI" },
      { nome: "Rio de Janeiro", sigla: "RJ" },
      { nome: "Rio Grande do Norte", sigla: "RN" },
      { nome: "Rio Grande do Sul", sigla: "RS" },
      { nome: "Rondônia", sigla: "RO" },
      { nome: "Roraima", sigla: "RR" },
      { nome: "Santa Catarina", sigla: "SC" },
      { nome: "São Paulo", sigla: "SP" },
      { nome: "Sergipe", sigla: "SE" },
      { nome: "Tocantins", sigla: "TO" },
    ];
    const resp = BaseState.filter((item) => item.sigla === str);
    return resp[0].nome;
  }
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const query: IQuery = { tag: "studio" };
    dispatch(ListUnidadesTagsActions.request(query));
  }, [dispatch]);

  const { data: listUnitsData, loading: listUnitsLoading } = useSelector<
    RootStateOrAny,
    ListUnidadesTags
  >((state) => state.listUnidadesTags);

  //buscando estados unicos na listagen
  const uniqueStates = [...new Set(listUnitsData.map((item) => item.UF))];
  uniqueStates.sort();
  //criando as opções de estado com base nas unidades
  const statesContent = uniqueStates.map((item) => ({
    label: ConvertToName(item),
    value: item,
  }));

  useEffect(() => {
    if (selectedState) {
      let allElements = document.querySelectorAll(
        ".react-select__single-value.css-1nll5d0-singleValue"
      );
      if (allElements.length > 1) {
        allElements[1].textContent = "Selecione...";
      }

      const unitsByState = listUnitsData.filter(
        (item) => item.UF === selectedState.value
      );
      setSaveCities(unitsByState);
      const selectContent = unitsByState.map((item) => ({
        label: item.Nome,
        value: item.Nome,
      }));
      selectContent.sort((a, b) => a.label.localeCompare(b.label));
      setCityOptions(selectContent);
    }
  }, [selectedState]);

  const onSelect = useCallback(
    (unit) => {
      if (googleCampaign) {
        const campaignParams = querystring.parse(googleCampaign);
        dispatch(CampaignActions.setCampaign(campaignParams));
      }
      const strSelectedCity = selectedCity.toString();
      GTMDataLayer.B2CFormChosenUnit({
        cidade: strSelectedCity,
        estado: selectedState?.value,
        unidade: unit.Nome,
      });
      history.push({
        pathname: `/agendar-avaliacao/${unit.IdUnidade}`,
        state: unit,
      });
    },
    [dispatch, googleCampaign, history, selectedCity, selectedState]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          state: Yup.string().required("Obrigatório"),
          city: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        ReactPixel.fbq("track", "Lead", {
          content_name: "Lead",
        });
        ReactGA.ga("event", "conversion", {
          send_to: "AW-640319643/8U5OCIri_9ABEJuBqrEC",
        });
        GTMDataLayer.B2CFormLocation({
          cidade: data.city,
          estado: data.state,
        });

        setSelectedCity(data.city);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors]
  );

  const SearchForm: React.FC = () => {
    return (
      <S.Wrapper>
        <S.Title>Agende a sua Avaliação</S.Title>
        <S.Text>
          Comece selecionando o seu estado e a sua cidade, que iremos encontrar
          as unidades mais próximas de você.
        </S.Text>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            name="state"
            label="Selecione seu estado:"
            formLabelColor="light"
            options={statesContent}
            onChange={(e) => setSelectedState(e)}
            defaultValue={selectedState}
          />
          <Select
            name="city"
            formLabelColor="light"
            label="Selecione a unidade:"
            isDisabled={listUnitsLoading || cityOptions.length === 0}
            isLoading={listUnitsLoading}
            options={cityOptions}
          />
          {!listUnitsLoading && selectedState && cityOptions.length === 0 && (
            <S.Warning>
              Nenhuma unidade encontrada para o estado selecionado.
            </S.Warning>
          )}
          <S.SubmitButton>
            Agendar uma Avaliação <S.ArrowRightIcon />
          </S.SubmitButton>
        </Form>
      </S.Wrapper>
    );
  };

  const Units: React.FC = () => {
    if (selectedCity.length > 0) {
      const filterUnits = saveCityes.filter(
        (item) => item.Nome === selectedCity
      );
      console.log("filterUnits: ", filterUnits);
      return (
        <>
          <S.Wrapper>
            <S.BackButton onClick={() => setSelectedCity([])}>
              <S.BackgroundIcon>
                <S.BackIcon />
              </S.BackgroundIcon>
              Voltar
            </S.BackButton>
            <S.Title>
              Marque sua
              <span> avaliação2</span>
            </S.Title>
            <S.Text>
              Encontramos {filterUnits.length} unidades para a cidade
              selecionada! Selecione uma delas para fazer sua avaliação.
            </S.Text>
            <S.UnitsList>
              {filterUnits.map((unit) => (
                <UnitCard
                  key={uuidV4()}
                  unit={unit}
                  onSelect={onSelect}
                  avaliacao={true}
                />
              ))}
            </S.UnitsList>
          </S.Wrapper>
        </>
      );
    }
    return <></>;
  };

  return (
    <S.Wrapper>
      {selectedCity.length === 0 ? <SearchForm /> : <Units />}
    </S.Wrapper>
  );
};
