import React, { useCallback } from "react";
import { convertToSlug } from "utils";
import * as S from "./styles";

import { IUnidade } from "interfaces/unidade";

interface IUnitCardProps {
  unit: IUnidade | any;
  onSelect: Function;
  avaliacao: boolean;
}

export const UnitCard: React.FC<IUnitCardProps> = ({
  unit,
  onSelect,
  avaliacao,
}) => {
  const handleClick = useCallback(
    (unit) => {
      if (avaliacao) {
        return onSelect(unit);
      }

      return onSelect(`unidade/${unit.Sigla}/${convertToSlug(unit.Nome)}`);
    },
    [avaliacao, onSelect]
  );
  return (
    <S.Container>
      <S.Column className="address">
        <S.Name>{unit.Nome}</S.Name>
        <S.Text>{unit.Endereco}</S.Text>
      </S.Column>
      {(unit.Telefone1 || unit.Telefone2) && (
        <S.Column>
          <S.TitlePhone>Telefone{unit.Telefone2 && "s"}:</S.TitlePhone>
          <S.Text>
            {unit.Telefone1 && (
              <p>
                <S.PhoneIcon />
                {unit.Telefone1}
              </p>
            )}
            {unit.Telefone2 && (
              <p>
                <S.PhoneIcon />
                {unit.Telefone2}
              </p>
            )}
            {unit.TelefoneWhatsApp && (
              <p>
                <S.WhatsappIcon />
                {unit.TelefoneWhatsApp}
              </p>
            )}
          </S.Text>
        </S.Column>
      )}
      <S.ViewButton onClick={() => handleClick(unit)}>
        <S.BackgroundIcon>
          <S.ArrowRightIcon />
        </S.BackgroundIcon>
        Agendar
      </S.ViewButton>
    </S.Container>
  );
};
