import React, { useRef, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { OptionTypeBase } from "react-select";
import { Form } from "@unform/web";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import { v4 as uuidV4 } from "uuid";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import querystring from "querystring";

import { statesOptions } from "utils";
import { useValidation } from "hooks";

import { Braces, Game, Treatments } from "components/Home";

import { Modal } from "components/Shared";

import { Scaffold, UnitCard, Footer } from "components/Shared";
import { Select } from "components/Shared/Form";
import { ListUnidadesState, ListUnidadesActions } from "store/ducks/unidades";
import { CampaignActions } from "store/ducks/campaign";
import GTMDataLayer from "utils/GTM-dataLayer";
import boneco from "assets/images/thop-apontando.png";
import * as S from "./styles";
import { fi } from "date-fns/locale";
import { IUnidade } from "interfaces/unidade";
import { Button } from "react-scroll";

interface ILocationState {
  city: string;
}

interface IParams {
  lineo: string;
  googleCampaign: string;
}
interface IQuery {
  state: string;
  lineo?: string;
}

type Location = {
  latitude: number | null;
  longitude: number | null;
};

type Address = {
  city: any | null;
  state: any | null;
};

export const BookingFindUnit: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { state } = useLocation<ILocationState>();
  const { lineo, googleCampaign } = useParams<IParams>();
  const { handleFormErrors } = useValidation();
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<OptionTypeBase | null>(
    null
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<IUnidade[]>([]);

  const [popupOpen, setPopupOpen] = useState(true);

  // geolocation variables
  const [location, setLocation] = useState<Location>({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    setSearchTerm("");
  }, []);

  const [error, setError] = useState<string | null>(null);

  const getGeoLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
        // console.log("coordenadas: ", { latitude, longitude });

        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDTeEPmp4STYKAsvGgcEHVqBqS9z103BVY`
          );
          const data = await response.json();
          if (data.status === "OK") {
            const addressComponents = data.results[0].address_components;
            const city = addressComponents.find((component: any) =>
              component.types.includes("administrative_area_level_2")
            )?.long_name;

            const stateShort = addressComponents.find((component: any) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name;

            const stateLong = addressComponents.find((component: any) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name;

            const state = {
              label: stateLong.trim(),
              value: stateShort.trim(),
            };

            setSelectedState(state);
            setSelectedCity(city);

            const exists =
              listUnitsData.filter(
                (unit) =>
                  unit.Cidade === city.toUpperCase() && unit.UF === state.value
              ).length > 0;

            if (exists) {
              setSelectedCity(city);
            }
          } else {
            setError("Failed to retrieve address information");
          }
        } catch (err: any) {
          setError(err.message);
        }
      },
      (error) => {
        console.log("error", error);
        setError(error.message);
      }
    );
  }, []);

  useEffect(() => {
    getGeoLocation();
  }, [getGeoLocation]);

  function redirectPop() {
    window.location.href = "https://orthopride.com.br/agendar-avaliacao";
  }

  const { data: listUnitsData, loading: listUnitsLoading } = useSelector<
    RootStateOrAny,
    ListUnidadesState
  >((state) => state.listUnidades);

  const onSelect = useCallback(
    (unit) => {
      if (googleCampaign) {
        const campaignParams = querystring.parse(googleCampaign);
        dispatch(CampaignActions.setCampaign(campaignParams));
      }
      GTMDataLayer.B2CFormChosenUnit({
        cidade: selectedCity || "",
        estado: selectedState?.value,
        unidade: unit.Nome,
      });
      history.push({
        pathname: `/agendar-avaliacao/${unit.IdUnidade}`,
        state: unit,
      });
    },
    [dispatch, googleCampaign, history, selectedCity, selectedState]
  );

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          state: Yup.string().required("Obrigatório"),
          city: Yup.string().required("Obrigatório"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        ReactPixel.fbq("track", "Lead", {
          content_name: "Lead",
        });
        ReactGA.ga("event", "conversion", {
          send_to: "AW-640319643/8U5OCIri_9ABEJuBqrEC",
        });
        GTMDataLayer.B2CFormLocation({
          cidade: data.city,
          estado: data.state,
        });

        setSelectedCity(data.city);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [handleFormErrors]
  );

  const getUnits = useCallback(
    (state) => {
      const query: IQuery = {
        state: state,
      };
      if (lineo) {
        query.lineo = "lineo";
      }

      dispatch(ListUnidadesActions.request(query));
    },
    [dispatch, lineo]
  );

  const setCities = useCallback(() => {
    const data = listUnitsData.map((unit) => ({
      label: unit.Cidade.toUpperCase(),
      value: unit.Cidade,
    }));

    const uniqueData: any[] = [];

    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      if (
        !uniqueData.some(
          (i) => i.value.toUpperCase() === item.value.toUpperCase()
        )
      ) {
        uniqueData.push(item);
      }
    }

    const uniqueDataOrder = uniqueData.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );

    setCityOptions(uniqueDataOrder);
  }, [listUnitsData]);

  const getLocationCity = useCallback(() => {
    if (state) {
      setSelectedCity(state.city);
    }
  }, [state]);

  useEffect(() => {
    if (selectedState) {
      getUnits(selectedState.value);
    }
  }, [getUnits, selectedState]);

  useEffect(() => {
    setCities();
  }, [setCities]);

  useEffect(() => {
    getLocationCity();
  }, [getLocationCity]);

  useEffect(() => {
    ReactPixel.fbq("track", "ViewContent", {
      content_name: "Agendamento inicial",
    });
  }, []);

  const SearchForm: React.FC = () => {
    return (
      <S.Wrapper>
        <S.Title>Agende a sua Avaliação</S.Title>
        <S.Text>
          Comece selecionando o seu estado e a sua cidade, que iremos encontrar
          as unidades mais próximas de você.
        </S.Text>
        <S.Aviso>Atenção: Não temos convênio ou parceria com o SUS.</S.Aviso>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            name="state"
            label="Selecione seu estado:"
            formLabelColor="light"
            options={statesOptions}
            onChange={(e) => setSelectedState(e)}
            defaultValue={selectedState}
          />
          <Select
            name="city"
            formLabelColor="light"
            label="Selecione sua cidade:"
            isDisabled={listUnitsLoading || cityOptions.length === 0}
            isLoading={listUnitsLoading}
            options={cityOptions}
          />
          {!listUnitsLoading && selectedState && cityOptions.length === 0 && (
            <S.Warning>
              Nenhuma unidade encontrada para o estado selecionado.
            </S.Warning>
          )}
          <S.SubmitButton>
            Agendar uma Avaliação <S.ArrowRightIcon />
          </S.SubmitButton>
        </Form>
      </S.Wrapper>
    );
  };

  function removerAcentos(texto: string): string {
    return texto
      .normalize("NFD") // Decomposição dos caracteres acentuados
      .replace(/[\u0300-\u036f]/g, ""); // Remove os diacríticos
  }
  // const textoOriginal = "çáéíóúãõâêîôûÁÉÍÓÚÃÕÇ";
  // const textoSemAcento = removerAcentos(textoOriginal);
  // console.log("Texto sem acentos:", textoSemAcento); // "caeiouaoaeiouAEIOUAOC"

  const Units: React.FC = () => {
    if (selectedCity) {
      const filterUnits = listUnitsData
        .filter(
          (unit) => unit.Cidade?.toUpperCase() === selectedCity.toUpperCase()
        )
        .sort((a, b) => (a.Ordem ?? 0) - (b.Ordem ?? 0));

      let filtered: any = [];
      if (searchTerm) {
        const noAccent = removerAcentos(searchTerm);
        filtered = filterUnits.filter(
          (unidade) =>
            unidade.Nome.toLowerCase().includes(noAccent.toLowerCase()) ||
            unidade.Nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            unidade.Bairro.toLowerCase().includes(noAccent.toLowerCase()) ||
            unidade.Bairro.toLowerCase().includes(searchTerm.toLowerCase()) ||
            unidade.Cidade.toLowerCase().includes(noAccent.toLowerCase()) ||
            unidade.Cidade.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      const unidadesParaMostrar = searchTerm ? filtered : filterUnits;

      return (
        <>
          <S.Wrapper>
            <S.BackButton onClick={() => setSelectedCity(null)}>
              <S.BackgroundIcon>
                <S.BackIcon />
              </S.BackgroundIcon>
              Voltar
            </S.BackButton>
            <S.Title>
              Marque sua
              <span> avaliação2</span>
            </S.Title>
            {searchTerm ? (
              <S.Text>
                Encontramos {filtered.length} unidades baseadas na pesquisa do
                termo "{searchTerm}".
              </S.Text>
            ) : (
              <S.Text>
                Encontramos {filterUnits.length} unidades para a cidade
                selecionada! Selecione uma delas para fazer sua avaliação.
              </S.Text>
            )}

            {unidadesParaMostrar.length > 3 || searchTerm !== "" ? (
              <S.AreaSearch>
                <input
                  id="busca"
                  name="busca"
                  type="text"
                  defaultValue={searchTerm}
                  placeholder="Buscar unidade..."
                  onBlur={(e) => setSearchTerm(e.target.value)}
                />
                <S.BtnBusca>Buscar</S.BtnBusca>
                <S.ClearSearch onClick={() => setSearchTerm("")}>
                  Limpar Busca
                </S.ClearSearch>
              </S.AreaSearch>
            ) : null}

            <S.UnitsList>
              {unidadesParaMostrar.map((unit: any) => (
                <UnitCard
                  key={uuidV4()}
                  unit={unit}
                  onSelect={onSelect}
                  avaliacao={true}
                />
              ))}
              {filtered.length === 0 && searchTerm ? (
                <p className="aviso">Nenhuma Unidade Encontrada</p>
              ) : null}
            </S.UnitsList>
          </S.Wrapper>
        </>
      );
    }
    return <></>;
  };

  return (
    <Scaffold>
      <S.Container>
        <S.Content>
          {!selectedCity && (
            <S.GroupScheadule>
              <div className="mleft">
                <img src={boneco} alt="Mascote" />
              </div>
              <div className="mright">
                <SearchForm />
              </div>
            </S.GroupScheadule>
          )}
          {selectedCity && (
            <S.GroupScheadule className="center">
              <Units />
            </S.GroupScheadule>
          )}
        </S.Content>
      </S.Container>
      <Braces />
      <Treatments />
      <S.GroupScheduleMid>
        {!selectedCity && (
          <S.Wrapper className="center">
            <S.Title>
              Agende a sua <span>Avaliação</span>
            </S.Title>
            <S.Text className="center">
              Comece selecionando o seu estado e a sua cidade, que iremos
              encontrar as unidades mais próximas de você.
            </S.Text>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Select
                name="state"
                label="Selecione seu estado:"
                formLabelColor="light"
                options={statesOptions}
                onChange={(e) => setSelectedState(e)}
                defaultValue={selectedState}
              />
              <Select
                name="city"
                formLabelColor="light"
                label="Selecione sua cidade:"
                isDisabled={listUnitsLoading || cityOptions.length === 0}
                isLoading={listUnitsLoading}
                options={cityOptions}
              />
              {!listUnitsLoading &&
                selectedState &&
                cityOptions.length === 0 && (
                  <S.Warning>
                    Nenhuma unidade encontrada para o estado selecionado.
                  </S.Warning>
                )}
              <S.SubmitButton>
                Agendar uma Avaliação <S.ArrowRightIcon />
              </S.SubmitButton>
            </Form>
          </S.Wrapper>
        )}
        {selectedCity && (
          <S.GroupScheadule className="center">
            <Units />
          </S.GroupScheadule>
        )}
      </S.GroupScheduleMid>
      <Game />
      {/* <Modal isOpen={popupOpen} onClickOutside={() => setPopupOpen(true)}>
        <S.Popup>
          <S.Close onClick={() => setPopupOpen(false)}>&times;</S.Close>
          <S.PopupContent onClick={redirectPop} />
        </S.Popup>
      </Modal> */}
      <Footer />
    </Scaffold>
  );
};
