import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { CookiesWarning } from "components/CookiesWarning";
import {
  Banner,
  Braces,
  Game,
  SponsorsFullPage,
  SponsorsHalfPage,
  Treatments,
  Surfers,
} from "components/Home";
import {
  Booking,
  Footer,
  Franchising,
  Modal,
  Scaffold,
} from "components/Shared";

import { FetchTagIndexActions } from "store/ducks/unidades";

import * as S from "./styles";

export const Home: React.FC = () => {
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState<boolean>(true);
  function redirectPop() {
    window.location.href = "https://orthopride.com.br/agendar-avaliacao";
  }

  useEffect(() => {
    dispatch(FetchTagIndexActions.request("general"));
  }, [dispatch]);

  return (
    <>
      {/* <Modal isOpen={popupOpen} onClickOutside={() => setPopupOpen(true)}>
        <S.Popup>
          <S.Close onClick={() => setPopupOpen(false)}>&times;</S.Close>
          <S.PopupContent onClick={redirectPop} />
        </S.Popup>
      </Modal> */}

      <Scaffold>
        <Banner />
        <Braces />
        <Treatments />
        <Game />
        <Franchising />
        <Surfers />
        <SponsorsFullPage />
        {/* <SponsorsHalfPage /> */}
        <Booking />
        <Footer />
        <CookiesWarning />
      </Scaffold>
    </>
  );
};
